import { space } from "../../../design-tokens";

export const styles = {
    carousel: {
        position: "relative",
        color: "transparent",
    },
    carouselSlider: {
        /* Snap mandatory on horizontal axis  */
        scrollSnapType: "x mandatory",
        overflowY: "hidden",
        display: "flex",
        alignItems: "start",
        msOverflowStyle: "none", // For Internet Explorer, Edge
        scrollbarWidth: "thin transparent", // For Firefox
        WebkitOverflowScrolling: "touch", // Enable Safari touch scrolling physics which is needed for scroll snap
        color: "inherit",
        "::-webkit-scrollbar": {
            width: ["2px !important", "4px !important"],
            height: ["2px !important", "16px !important"],
        },
        paddingBottom: [3, 0],
        overflowX: ["auto", "auto"],
        "::-webkit-scrollbar-track": {
            background: "transparent",
            marginRight: [4, 4, 0],
            borderBottom: ["2px solid", "transparent"],
            borderColor: ["gray.50", "transparent"],
        },
        "::-webkit-scrollbar-thumb": {
            borderBottom: "2px solid",
            borderColor: ["black ", "transparent"],
        },
        _hover: {
            "::-webkit-scrollbar-track": {
                background: "transparent",
                marginRight: space["6"],
                marginLeft: space["6"],
                borderBottom: "2px solid",
                borderColor: "gray.50",
            },
            "::-webkit-scrollbar-thumb": {
                borderBottom: "2px solid",
                borderColor: "black",
            },
        },
    },
    carouselNavigation: {
        mr: [4, 16],
    },
    swiperNavigationContainer: {
        display: ["flex", "flex"],
        w: "100%",
        position: "absolute",
        bottom: ["3", "6"],
        gap: "0.2",
        right: [null, "6"],
        justifyContent: {
            base: "flex-start",
            sm: "flex-end",
        },
        left: {
            base: "3",
            sm: "0",
        },
    },
    iconButton: {
        left: [0, null],
        right: [0, null],
        minW: "0px",
        flex: "0 0 auto",
    },
    swipergalleryButton: {
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
    },
    swiperContainer: {
        width: "100%",
        position: "relative",
        transition: "opacity 0.3s ease",
        "& .swiper-pagination-timer .swiper-pagination-bullet-active::after": {
            animationPlayState: "running",
        },
        _hover: {
            "& .swiper-pagination-timer .swiper-pagination-bullet-active::after": {
                animationPlayState: ["running", "paused"],
            },
            "& .swiper-button-next, .swiper-button-prev": {
                opacity: 1,
            },
        },
        "& .swiper-wrapper": {
            transitionTimingFunction: "cubic-bezier(0.85, 0, 0.15, 1)",
        },
        "& .swiper-pagination": {
            bottom: "inherit",
            mt: 6,
            display: "flex",
            justifyContent: "center",
            gap: 2,
            position: "relative",
            "& .swiper-pagination-bullet": {
                backgroundColor: "gray.100",
            },
            " .swiper-pagination-bullet-active": {
                backgroundColor: "black",
            },
        },
        "& .swiper-pagination-timer": {
            bottom: "inherit",
            mt: 6,
            display: "flex",
            justifyContent: "center",
            gap: 2,
            position: "relative",
            "& .swiper-pagination-bullet": {
                backgroundColor: "gray.100",
                width: 2,
                zIndex: 1,
                opacity: 1,
            },
            " .swiper-pagination-bullet-active": {
                backgroundColor: "gray.100",
                width: 6,
                borderRadius: "10px",
            },
            " .swiper-pagination-bullet-active::after": {
                position: "absolute",
                content: "''",
                height: 2,
                width: 6,
                backgroundColor: "black",
                borderRadius: "10px",
                animation: "slide 5s ease-in ",
                transform: "scaleX(0)",
                WebkitTransform: "scaleX(0)",
                transformOrigin: "left",
                animationFillMode: "forwards",
                "@keyframes slide": {
                    "0%": {
                        WebkitTransform: "scaleX(0.33)",
                        transform: "scaleX(0.33)",
                        borderRadius: "50%",
                    },
                    "100%": {
                        WebkitTransform: "scaleX(1)",
                        transform: "scaleX(1)",
                    },
                },
            },
        },
        "& .swiper-button-next, .swiper-button-prev": {
            "&:after": {
                content: "''",
            },
        },
        "& .swiper-button-next[aria-disabled='true'], .swiper-button-prev[aria-disabled='true']": {
            visibility: "hidden",
        },
    },
    carouselPaginationDot: {
        width: 2,
        height: 2,
        borderRadius: "50%",
        backgroundColor: "gray.100",
        transition: "background-color 0.3s ease",
        position: "relative",
    },
    iconIndicatorContainer: {
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        justifyContent: "end",
        padding: {
            base: "0 15px",
            sm: "0 30px",
        },
        right: [null, 0],
        left: {
            base: 0,
            sm: "unset",
        },
        zIndex: [1, 1],
        width: "fit-content",
        // height: "100%",
        bottom: 0,
        top: "unset",
    },
    iconIndicatorSubContainer: {
        minHeight: "30%",
        bottom: "0",
        position: ["static", "sticky"],
    },
    iconContainer: {
        padding: "6",
        height: "100%",
        justifyContent: "end",
    },
};
